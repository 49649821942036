import React from 'react';
import Layout from '../components/layout';
import Sender from '../parcelFlow/Sender';
import Container from '../components/Container';
import FullHeightColumn from '../components/FullHeightColumn';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import * as analytics from '../utils/analytics';

export default ({ pageContext }) => {
  analytics.usePageCategory('paketit');
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Layout
      title={translate('buyParcel.sender.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      checkShopDisturbance={true}
      showNavigation={false}
    >
      <Container variant="parcel">
        <FullHeightColumn>
          <Sender />
        </FullHeightColumn>
      </Container>
    </Layout>
  );
};
